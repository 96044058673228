.password-reset-container {
    display: flex;
    height: 100vh;
}

.password-reset-left {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
}

.password-reset-form {
    max-width: 400px;
    width: 100%;
}