.detailed-transaction-history__container {
    background-color: #F7F7F7;
    border-radius: 8px;
    padding: 20px;
    min-height: 100vh;
    box-sizing: border-box;
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header-section h2 {
    font-size: 1.5em;
    font-weight: 600;
}

.transaction-summary {
    display: flex;
    gap: 20px;
}

.transaction-summary span {
    font-size: 1em;
    color: #000;
}

.transaction-summary strong {
    color: #39B7E9;
}

.transaction-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.date-range-picker {
    position: relative;
}

.filter-date-range-button {
    background-color: #E5F3FF;
    border: none;
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.date-picker-container {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.date-picker-input {
    width: 100%;
}

.confirm-button {
    margin-top: 10px;
    background-color: #39B7E9;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.filter-button {
    background-color: #39B7E9;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.export-button {
    background-color: #E5F3FF;
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.detailed-transaction-history__table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    border-radius: 5px;
}

.detailed-transaction-history__table th, .detailed-transaction-history__table td {
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
}

