.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.pagination-info {
    display: flex;
    align-items: center;
}

.pagination-controls {
    display: flex;
    align-items: center;
}

.pagination-controls span {
    margin: 0 10px;
    font-size: 14px;
    color: #666;
}

.pagination-button {
    margin: 0 5px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #39B7E9;
}

.pagination-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #ccc;
}

.pagination-button.active {
    background-color: #39B7E9;
    color: #fff;
    border-color: #39B7E9;
}
