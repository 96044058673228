.otp-verification-container {
    display: flex;
    height: 100vh;
}

.otp-verification-left {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
}

.otp-verification-form {
    max-width: 400px;
    width: 100%;
}

.otp-input-container .otp-input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    margin-right: 10px;
    text-align: center;
}

.otp-input:last-child {
    margin-right: 0;
}