.sub-text {
    color: #888;
    font-size: 14px;
}

.status {
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    width: 64px;
    text-align: center;
}

.status.sent {
    background-color: #3EBF0F; 
}

.status.failed {
    background-color: #FF1515;
}
