html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .group-sms-container {
    background-color: #F7F7F7;
    min-height: 90vh; 
    margin: 0 auto; 
    padding: 30px; 
    box-sizing: border-box; 
  }
  
  .text-primary-custom {
    color: #39B7E9; 
  }
  
  .link-custom {
    color: #115BC7; 
    text-decoration: none; 
  }
  
  .link-custom:hover {
    text-decoration: underline; 
  }
  
  .btn-primary-custom {
    background-color: #39B7E9;
    color: white;
    border: none;
    padding: 10px 30px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-secondary-custom {
    background-color: #E3F5FC;
    color: #39B7E9;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-control {
    margin-bottom: 10px;
  }
  
  .mt-1 {
    margin-top: 5px;
  }
  
  .mt-2 {
    margin-top: 10px;
  }
  
  .mt-3 {
    margin-top: 15px;
  }
  
  .mb-3 {
    margin-bottom: 15px;
  }
  
  .text-right {
    text-align: right;
  }
  
  .text-left {
    text-align: left;
  }
  