
.webhook-config {
    padding: 40px;
    width: 100%;
    margin: auto;

}

.webhook-config input{
    width: 400px;
}

.webhook-config button {
    display: block;
    width: 100px;
    padding: 10px 0;
    border: none;
    border-radius: 5px;
    background-color: #39B7E9;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}