.profile-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
}

.profile-photo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.photo-instructions {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
}

.photo-buttons {
    margin-top: 10px;
}

.change-button,
.remove-button {
    display: block;
    width: 80px;
    padding: 5px;
    margin-top: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.change-button {
    background-color: #007bff;
    color: white;
}

.remove-button {
    background-color: #e3f5fc;
    color: #39b7e9;
}

.profile-form-section {
    flex-grow: 1;
    margin-left: 20px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
}

label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

input, select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f7f7f7; 
    color: #333; 
}

input::placeholder, select::placeholder {
    color: #888; 
}

.save-button {
    width: 100%;
    padding: 10px;
    background-color: #39b7e9;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    max-width: 100px;
}
