.container-add-contact {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #F7F7F7;
    height: 91%;
  }
  
  .header-add-contact {
    text-align: left;
    font-size: 24px;
    margin: 0 20px;
    margin-bottom: 10px;
  }
  
  .subheader-add-contact {
    text-align: left;
    font-size: 14px;
    color: #666;
    margin: 0 20px;
    margin-bottom: 30px;
  }
  
  .form-add-contact {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin: 0 20px;
  }
  
  .row-add-contact {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .column-add-contact {
    width: 48%;
    display: flex;
    flex-direction: column;
  }
  
  .label-add-contact {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .input-add-contact {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  .button-add-contact {
    padding: 10px 20px;
    margin: 20px 0 0 0; 
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100px;
    text-align: center;
  }
  