.signin-container {
    display: flex;
    height: 100vh;
}

.signin-left  {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
}

.signin-left .signin-form {
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.signin-left .signin-form h2 {
    margin-bottom: 10px;
}

.signin-left .signin-form p {
    margin-bottom: 20px;
}

.signin-left .form-group {
    margin-bottom: 15px;
    text-align: left;
}

.signin-left .form-group label {
    display: block;
    margin-bottom: 5px;
}

.signin-left .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
}

.signin-left .password-container {
    position: relative;
}

.signin-left .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.signin-left .form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.signin-left .form-options label {
    margin-left: 5px;
}

.signin-left .btn-primary {
    width: 100%;
    padding: 10px;
    background-color: #39B7E9;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.signin-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #263B80;
    position: relative;
}

.signin-right .logo {
    width: 400px;
}

.signin-right .top-right-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: auto;
}

.signin-right .buttom-left-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    height: auto;
}
