html, body {
    overflow: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
}

.contacts {
    background-color: #F7F7F7;
    padding: 20px;
  
}

.import-button {
    background-color: #0099FF;
    color: white;
    cursor: pointer;
    padding: 7px 20px; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    box-sizing: border-box;
    margin-left: 20px; 
}


.actions {
    display: flex;
    gap: 10px;
    align-items: center;
}

.add-button, .search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.add-button {
    background-color: #0099FF;
    color: white;
    cursor: pointer;
    padding: 7px 20px;
}

