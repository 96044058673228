.update-password-container {
    display: flex;
    height: 100vh;
}

.update-password-left {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
}

.update-password-form {
    max-width: 400px;
    width: 100%;
}

.password-container {
    position: relative;
}

.password-toggle-text {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #007bff;
}

.update-password-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #263B80;
    position: relative;
}

.update-password-right .top-right-image,
.update-password-right .bottom-left-image {
    width: 300px;
    height: auto;
    position: absolute;
}

.update-password-right .top-right-image {
    top: 0;
    right: 0;
}

.update-password-right .bottom-left-image {
    bottom: 0;
    left: 0;
}

.update-password-right .logo {
    width: 400px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.password-success-modal {
    flex: 3;
    margin: auto;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11; 
}

.password-success-content {
    background-color: #fff;
    padding: 80px 140px !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}



@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.password-success-content .fa-circle-check {
    animation: bounce 2s infinite; 
    color: #007bff;
}