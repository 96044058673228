.call-initiator-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    text-align: center;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  .submit-button.loading {
    background-color: #0056b3;
  }
  
  .response-message {
    margin-top: 15px;
    text-align: center;
    color: #d9534f;
  }

  .fb-icon{
    color: #337FFF;
  }

  .btn-connect{
    color: #929292;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    font-size: 10px;
    font-weight: bold;
  }

  .btn-connected{
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }

  .btn-copy{
    background-color: #3EBF0F;
    color: #fff;
  }

  .word-break{
    word-break: break-all;
  }
  