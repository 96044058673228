.main-component {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f8f9fa;
}

.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.main-component-sidebar {
    width: 30%;
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.main-component-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.main-component-sidebar ul li {
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    padding: 9px;
    border-bottom: 1px solid #ddd; 
}

.main-component-sidebar ul li.active {
    background-color: #e9f5ff;
    color: #007bff;
    font-weight: bold;
    border-radius: 8px;
}

.main-component-sidebar ul li a {
    text-decoration: none;
    color: inherit;
    display: block;
}

.main-component-sidebar ul li a:hover {
    text-decoration: none;
}

.main-component-content {
    width: 70%;
    margin-left: 20px;
    background-color: #fff; 
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
    height: 489px;
}


.fa-angle-right {
    margin-left: 10px;
}