html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.direct-sms-container {
    background-color: #F7F7F7;
    min-height: 90vh; 
    margin: 0 auto; 
    padding: 30px; 
    box-sizing: border-box; 
}

.text-primary-custom {
    color: #39B7E9; 
  }
  
  .link-custom {
    color: #115BC7; 
    text-decoration: none; 
  }
  
  .link-custom:hover {
    text-decoration: underline; 
  }
  