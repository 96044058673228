.list-group {
    
  }
  
  .list-group-item {
  }

  .btn-dark-gray{
    background-color: #6C7888;
    border-radius: 10px;
    color: white;
  }

  .vh-100 {
    height: 100vh; /* Make the container take up the full height of the viewport */
  }
  
  .chat-container {
    flex-grow: 1; /* This makes the chat container fill available vertical space */
    overflow-y: auto; /* Allow scrolling if there are more messages */
    padding: 15px;
  }
  
  /* .input-group {
    height: 60px; 
    background-color: white;
  } */

  .message-input {
    max-height: 80px;  /* Limit textarea height to 2 lines */
    overflow-y: auto;  /* Allow scrolling if there is more text */
    border-radius: 8px !important;  /* Rounded corners for the input box */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group-append {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);  /* Center the icon vertically inside the input */
    z-index: 10;
  }
  
  .input-group {
    position: relative;
    width: 100%;
  }
  
  .contact-img {
    width: 50px;
    height: 50px;
  }
  
  .channel-icon {
    font-size: 12px;
    background-color: #fff;
    padding: 2px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  
  .list-group-item {
    padding: 10px;
  }
  
  .selected {
    background-color: #f0f0f0; /* Highlight selected contact */
  }
  
  .list-group h6 {
    margin-bottom: 5px;
  }
  
  .dropdown-menu {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(-10px);
}

.dropdown-menu.show {
    opacity: 1;
    transform: translateY(0);
}

.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3EBF0F;
}