/* SignUp.css */
.signup-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.signup-left {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 10px;  /* Reduced padding */
}

.signup-left .signup-form {
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.signup-left .signup-form h2 {
    margin-bottom: 10px;
    font-size: 22px;  /* Reduced font size */
    color: #333;
}

.signup-left .signup-form p {
    margin-bottom: 15px;  /* Reduced margin */
    color: #666;
    font-size: 14px;  /* Reduced font size */
}

.signup-left .form-row {
    display: flex;
    justify-content: space-between;
}

.signup-left .form-group {
    margin-bottom: 10px; 
    text-align: left;
    flex: 1;
    margin-right: 10px;
}

.signup-left .form-group:last-child {
    margin-right: 0;
}

.signup-left .form-group label {
    display: block;
    margin-bottom: 3px;  
    font-size: 12px;  
    color: #333;
}

.signup-left .form-group input,
.signup-left .form-group select {
    width: 100%;
    padding: 8px;  
    border: 1px solid #ccc;
    border-radius: 4px;
}

.signup-left .password-container {
    position: relative;
}

.signup-left .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.signup-left .btn-primary {
    width: 100%;
    padding: 10px;
    background-color: #39B7E9;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 14px; 
    cursor: pointer;
    transition: background-color 0.3s;
}

.signup-left .btn-primary:hover {
    background-color: #2e9bd2;
}

.signup-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #263B80;
    position: relative;
}

.signup-right .logo {
    width: 400px; 
}

.signup-right .top-right-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: auto;
}

.signup-right .bottom-left-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;  
    height: auto;
}

.signup-form p {
    text-align: left;
    font-size: 12px;  
    margin-bottom: 5px; 
}

.phone-number-container {
    display: flex;
    align-items: center;
}

.country-code-dropdown {
    margin-right: 10px;
}
