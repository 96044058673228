.deactivate-account {
    padding: 40px;
    width: 100%;
    margin: auto;

}

.deactivate-account textarea {
    background-color: white;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
}

.deactivate-account button {
    display: block;
    width: 200px;
    padding: 10px 0;
    border: none;
    border-radius: 5px;
    background-color: #39B7E9;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 50px;
}

.deactivate-account button:hover {
    background-color: #1C8CDC;
}
