.navbar {
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px  #e9f5ff;
    display: flex;
    align-items: center;
}

.new-message-btn {
    background-color: #39B7E9;
    border: none;
    margin-right: 20px;
}

.profile-section {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.profile-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
}

.profile-btn {
    background-color: transparent;
    border: none;
    color: black;
    font-weight: bold;
}

.notification-icon {
    color: #3EBF0F;
    font-size: 24px;
    margin-right: 30px;
}


.toggle-icon-padding {
    padding-left: 8px;
}

.custom-search-input{
    margin-right: 8rem;
    width: 20rem;
}