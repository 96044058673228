.change-password-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  
}

.change-password-content {
    padding: 20px;
    width: 100%;
    max-width: 500px;
}

.change-password-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

.password-input {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    background-color: #f9f9f9;
}

.password-input input {
    flex: 1;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
}

.password-toggle-text {
    cursor: pointer;
    color: #007bff;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 12px;

}

.change-password-button {
    width: 100%;
    padding: 10px;
    background-color: #39b7e9;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    max-width: 200px;
}


.change-password-button {
    width: 100%;
    padding: 10px;
    background-color: #39b7e9;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    max-width: 200px;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}