.forgot-password-container {
    display: flex;
    height: 100vh;
}

.forgot-password-left {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
}

.forgot-password-left .forgot-password-form {
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.forgot-password-left .forgot-password-form h2 {
    margin-bottom: 10px;
}

.forgot-password-left .forgot-password-form p {
    margin-bottom: 20px;
}

.forgot-password-left .form-group {
    margin-bottom: 15px;
    text-align: left;
}

.forgot-password-left .form-group label {
    display: block;
    margin-bottom: 5px;
}

.forgot-password-left .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.forgot-password-left .btn-primary {
    width: 100%;
    padding: 10px;
    background-color: #39B7E9;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

