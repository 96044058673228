.sidebar {
    height: 100%;
    background-color: #FFFFFF !important;
    box-shadow: 2px 0 5px  #e9f5ff;
    z-index: 10;
}

.sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.sidebar-sticky::-webkit-scrollbar {
    width: 8px;
}

.sidebar-sticky::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

.sidebar-sticky::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 8px;
}

.sidebar-sticky::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.nav-link {
    color: #333;
    padding: 10px 15px 2px 0px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
}

.nav-link > span {
    padding-left: 2px;
    letter-spacing: -0.4px;
}

.nav-link:hover {
    background-color: #f8f9fa;
    color: #2bd815 !important;
    letter-spacing: 1px !important;
}

.nav-link svg{
    color: #2bd815;
}

.nav-link.active {
    /* background-color: #e9f5ff;
    color: #007bff; */
    border-bottom: 1px #3EBF0F solid;
}

.nav-link .mr-2 {
    margin-right: 10px;
}

.nav-item + .nav-item {
    margin-top: 10px;
}

.nav-item .nav.flex-column {
    max-height: 300px;
    overflow-y: auto;
}

.nav-item .nav.flex-column::-webkit-scrollbar {
    width: 8px;
}

.nav-item .nav.flex-column::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

.nav-item .nav.flex-column::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 8px;
}

.nav-item .nav.flex-column::-webkit-scrollbar-thumb:hover {
    background: #888;
}
