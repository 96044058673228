html, body {
    overflow: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
}

.main-content {
    overflow-y: auto; 
    overflow-x: hidden; 
    height: 100vh; 
    box-sizing: border-box;
}

.invoice {
    background-color: #F7F7F7;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.invoice h2,
.invoice p {
    margin: 0 0 20px 0;
    color: #666;
}

.invoice-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.invoice-table th {
    cursor: pointer;
    color: #263B80;
    font-size: 18.86px;
}

.invoice-table th,
.invoice-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 13px;
}

.status {
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
}

.status.paid {
    background-color: #3EBF0F;
}

.status.due {
    background-color: #FF1515;
}

.invoice-actions-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
}
