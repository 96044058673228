.main-topup {
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.main-topup__container {
    background-color: #ffffff;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    max-width: 700px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-topup__wallet {
    text-align: center;
    padding: 20px;
    background-color: #f5f9ff;
    border-radius: 8px;
    margin-bottom: 20px;
}

.main-topup__wallet h1 {
    margin: 0;
    padding: 10px 0;
    color: #39B7E9;
    font-size: 36px;
}

.main-topup__account-info {
    background-color: #e0f7ff;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
    text-align: center;
    border: 2px dashed #3B3B3B;
}

.main-topup__account-details-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.main-topup__account-details {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    align-items: center;
}

.main-topup__copy-icon {
    cursor: pointer;
    font-size: 16px;
    margin-left: 5px;
    position: relative;
}

.main-topup__copy-icon::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    display: none;
}

.main-topup__copy-icon:hover::before {
    display: block;
}

.main-topup__wallet-guide {
    background-color: #f5f9ff;
    padding: 20px;
    border-radius: 8px;
}
.main-topup__wallet-guide > div:first-of-type {
    margin-left: 20px;
    font-size: 20px;
}

.main-topup__wallet-guide h3 {
    margin: 0;
    font-size: 20px;
    margin-left: 3px;
}

.main-topup__wallet-guide > div:first-of-type p {
    margin: 0;
    font-size: 20px;
    margin-left: 3px;
    color: black;
}

.main-topup__wallet-guide p {
    margin: 0;
    padding-bottom: 10px;
    font-size: 14px;
    color: #666666;
}

.main-topup__guide-details {
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
}

.main-topup__guide-details ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #666666;
}

.main-topup__guide-details ul li {
    padding-bottom: 10px;
}

.main-topup__wallet button {
    background-color: #39B7E9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.main-topup__form {
    background-color: #ffffff;
    margin: 20px;
    border-radius: 8px;
    max-width: 350px;
    height: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-topup__form-container {
    padding: 20px;
}

.main-topup__form-container h3 {
    color: #39B7E9;
    margin-bottom: 20px;
}

.main-topup__form-group {
    margin-bottom: 15px;
}

.main-topup__form-group label {
    display: block;
    font-size: 14px;
    color: #666666;
    margin-bottom: 5px;
}

.main-topup__form-group select,
.main-topup__form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none; 
}

.main-topup__form-group select:focus,
.main-topup__form-group input:focus {
    border-color: transparent; 
}

.main-topup__form-group input[type="number"] {
    -moz-appearance: textfield;
}

.main-topup__form-group input::-webkit-outer-spin-button,
.main-topup__form-group input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.main-topup__total-amount {
    background-color: #E3F5FC5C;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: left;
    color: #39B7E9;
    font-size: 18px;
    font-weight: bold;
}

.main-topup__notice {
    margin-top: 20px;
}

.main-topup__notice h4 {
    margin: 0;
    font-size: 16px;
    color: #666666;
}

.main-topup__notice p {
    font-size: 14px;
    color: #666666;
}

.main-topup__see_full {
    background-color: #39B7E9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
    display: block;
    width: auto;
}

.main-topup__fund-wallet-btn {
    background-color: #39B7E9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
}

.main-topup__amount {
    color: #FF1515 !important;
}

.main-topup__modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.main-topup__modal-content {
    background-color: #fefefe;
    padding: 30px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.main-topup__modal-close {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.main-topup__modal-close:hover,
.main-topup__modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.main-topup__modal-prices {
    background-color: #f5f9ff;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
}

.main-topup__modal-prices table {
    width: 100%;
    border-collapse: collapse;
}

.main-topup__modal-prices th, .main-topup__modal-prices td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.main-topup__modal-prices th {
    font-weight: bold;
}

.main-topup__modal-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.main-topup__modal-btn:hover {
    background-color: #0056b3;
}
