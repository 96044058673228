.default-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content {
    display: flex;
    flex: 1;
}

.main-content {
    flex: 1;
}

.sidebar {
    width: 210px; 
    background-color: #f8f9fa;
}

.main-content {
    flex: 1;
    overflow-y: auto;
}

.bg-gray1{
    background-color: #F7F7F7;
}

.theme-bg{
    background-color: #3EBF0F;
}

.theme-fg{
    color: #3EBF0F;
}

.gray100{
    color: #929292;
}

.text-bold{
    font-weight: bold;
}

.list-group-item.selected {
    z-index: 2 !important;
    background-color: #F1F5F9 !important;
    border-color: #F1F5F9 !important;
    border-radius: 8px;
}

.bg-gray{
    background-color: #EEEEEE;
}

.lines-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}