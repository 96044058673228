.numbers {
    background-color: #F7F7F7;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.header {
    display: flex;
    margin-bottom: 20px;
}

.rent-number-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.numbers-invoice-table {
    width: 100%;
    border-collapse: collapse;
}

.numbers-invoice-table th,
.numbers-invoice-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.numbers-invoice-table th {
    background-color: #f2f2f2;
}

.status {
    display: inline-block;
    padding: 5px 10px;
    color: white;
    border-radius: 3px; 
}

.status.active {
    background-color: #3EBF0F;
}

.status.expired {
    background-color: #FF6F6F;
}

.status.inactive {
    background-color: #FFB74D;
}
