.api-token-container {
    padding: 20px;
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.key-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.key-block {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
    position: relative;
}

.key-block:last-child {
    margin-right: 0;
}

label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

textarea {
    width: 100%;
    height: 120px;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    background-color: #f0f8ff;
    resize: none;
    position: relative;
    outline: none; 
    border-color: transparent;
}

textarea:focus {
    border-color: transparent;
}

.copy-button {
    margin-top: 5px;
    padding: 5px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    outline: none;
}

.copy-button:hover {
    outline: none;
}

.renew-button {
    width: 150px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
}

.renew-button:focus {
    border-color: transparent;
}
