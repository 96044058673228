.confirm-account-container {
    display: flex;
    height: 100vh;
}

.confirm-account-left {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
}

.confirm-account-form {
    max-width: 400px;
    width: 100%;
}