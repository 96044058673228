.messaging-insight-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .custom-legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    max-width: 600px;
    gap: 20px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin: 5px 10px;
  }
  
  .color-circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .legend-text {
    font-size: 12px;
    color: #666;
    margin-right: 8px;
  }
  