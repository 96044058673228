.insight-component {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f8f9fa;
}

.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.insight-sidebar {
    width: 30%;
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    height: 489px;
}

.insight-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.insight-sidebar ul li {
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    padding: 9px;
    border-bottom: 1px solid #ddd; 
}

.insight-sidebar ul li.active {
    background-color: #e9f5ff;
    color: #007bff;
    font-weight: bold;
    border-radius: 8px;
}

.insight-sidebar ul li a {
    text-decoration: none;
    color: inherit;
    display: block;
}

.insight-sidebar ul li a:hover {
    text-decoration: none;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.action-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.action-button:hover {
    background-color: #0056b3;
}


.insight-content {
    width: 70%;
    margin-left: 20px;
    background-color: #fff; 
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
    height: 489px;
}

.insight-content--no-bg {
    width: 70%;
    margin-left: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
    height: 489px;
    background-color: transparent;
}


.fa-angle-right {
    margin-left: 10px;
}

.primary-action-button {
    background-color: #39B7E9;
    color: white; 
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    margin-left: 1rem;
    padding: 8px 16px;
   
}

.additional-action-button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    margin-left: 1rem;
}

.additional-action-button-1 {
    background-color: #39B7E9; 
    color: white; 
}


.additional-action-button-2 {
    background-color: #E3F5FC; 
    color: #39B7E9; 
}


